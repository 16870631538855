/*** Links ***/
.link {
  color: var(--color-celeste);
  font-weight: bold;
}

.link:hover {
  color: var(--color-boton-seleccionado);
  text-decoration: underline;
  cursor: pointer;
}

.pointer {
    cursor: pointer;
}
