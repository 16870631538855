/* CLASES PARA MÁRGENES DINÁMICOS */

/* Márgenes BOTTOM en unidades VH */
.margen-bottom-vh-1 {
  margin-bottom: 1vh;
}
.margen-bottom-vh-2 {
  margin-bottom: 2vh;
}

.margen-bottom-vh-3 {
  margin-bottom: 3vh;
}

.margen-bottom-vh-4 {
  margin-bottom: 4vh;
}

.margen-bottom-vh-5 {
  margin-bottom: 5vh;
}

.margen-bottom-vh-6 {
  margin-bottom: 6vh;
}

.margen-bottom-vh-7 {
  margin-bottom: 7vh;
}

.margen-bottom-vh-8 {
  margin-bottom: 8vh;
}

.margen-bottom-vh-9 {
  margin-bottom: 9vh;
}

.margen-bottom-vh-10 {
  margin-bottom: 10vh;
}

.margen-bottom-vh-11 {
  margin-bottom: 11vh;
}

.margen-bottom-vh-12 {
  margin-bottom: 12vh;
}

.margen-bottom-vh-13 {
  margin-bottom: 13vh;
}

.margen-bottom-vh-14 {
  margin-bottom: 14vh;
}

.margen-bottom-vh-15 {
  margin-bottom: 15vh;
}

.margen-bottom-vh-16 {
  margin-bottom: 16vh;
}

.margen-bottom-vh-17 {
  margin-bottom: 17vh;
}

.margen-bottom-vh-18 {
  margin-bottom: 18vh;
}

.margen-bottom-vh-19 {
  margin-bottom: 19vh;
}

.margen-bottom-vh-20 {
  margin-bottom: 20vh;
}

/* Márgenes TOP en unidades VH */
.margen-top-vh-1 {
  margin-top: 1vh;
}

.margen-top-vh-2 {
  margin-top: 2vh;
}

.margen-top-vh-3 {
  margin-top: 3vh;
}

.margen-top-vh-4 {
  margin-top: 4vh;
}

.margen-top-vh-5 {
  margin-top: 5vh;
}

.margen-top-vh-6 {
  margin-top: 6vh;
}

.margen-top-vh-7 {
  margin-top: 7vh;
}

.margen-top-vh-8 {
  margin-top: 8vh;
}

.margen-top-vh-9 {
  margin-top: 9vh;
}

.margen-top-vh-10 {
  margin-top: 10vh;
}

.margen-top-vh-11 {
  margin-top: 11vh;
}

.margen-top-vh-12 {
  margin-top: 12vh;
}

.margen-top-vh-13 {
  margin-top: 13vh;
}

.margen-top-vh-14 {
  margin-top: 14vh;
}

.margen-top-vh-15 {
  margin-top: 15vh;
}

.margen-top-vh-16 {
  margin-top: 16vh;
}

.margen-top-vh-17 {
  margin-top: 17vh;
}

.margen-top-vh-18 {
  margin-top: 18vh;
}

.margen-top-vh-19 {
  margin-top: 19vh;
}

.margen-top-vh-20 {
  margin-top: 20vh;
}

/* Márgenes LEFT en unidades VW */
.margen-left-vw-1 {
  margin-left: 1vw;
}

.margen-left-vw-2 {
  margin-left: 2vw;
}

.margen-left-vw-3 {
  margin-left: 3vw;
}

.margen-left-vw-4 {
  margin-left: 4vw;
}

.margen-left-vw-5 {
  margin-left: 5vw;
}

.margen-left-vw-6 {
  margin-left: 6vw;
}

.margen-left-vw-7 {
  margin-left: 7vw;
}

.margen-left-vw-8 {
  margin-left: 8vw;
}

.margen-left-vw-9 {
  margin-left: 9vw;
}

.margen-left-vw-10 {
  margin-left: 10vw;
}

.margen-left-vw-11 {
  margin-left: 11vw;
}

.margen-left-vw-12 {
  margin-left: 12vw;
}

.margen-left-vw-13 {
  margin-left: 13vw;
}

.margen-left-vw-14 {
  margin-left: 14vw;
}

.margen-left-vw-15 {
  margin-left: 15vw;
}

.margen-left-vw-16 {
  margin-left: 16vw;
}

.margen-left-vw-17 {
  margin-left: 17vw;
}

.margen-left-vw-18 {
  margin-left: 18vw;
}

.margen-left-vw-19 {
  margin-left: 19vw;
}

.margen-left-vw-20 {
  margin-left: 20vw;
}

/* Márgenes RIGHT en unidades VW */
.margen-right-vw-1 {
  margin-right: 1vw;
}

.margen-right-vw-2 {
  margin-right: 2vw;
}

.margen-right-vw-3 {
  margin-right: 3vw;
}

.margen-right-vw-4 {
  margin-right: 4vw;
}

.margen-right-vw-5 {
  margin-right: 5vw;
}

.margen-right-vw-6 {
  margin-right: 6vw;
}

.margen-right-vw-7 {
  margin-right: 7vw;
}

.margen-right-vw-8 {
  margin-right: 8vw;
}

.margen-right-vw-9 {
  margin-right: 9vw;
}

.margen-right-vw-10 {
  margin-right: 10vw;
}

.margen-right-vw-11 {
  margin-right: 11vw;
}

.margen-right-vw-12 {
  margin-right: 12vw;
}

.margen-right-vw-13 {
  margin-right: 13vw;
}

.margen-right-vw-14 {
  margin-right: 14vw;
}

.margen-right-vw-15 {
  margin-right: 15vw;
}

.margen-right-vw-16 {
  margin-right: 16vw;
}

.margen-right-vw-17 {
  margin-right: 17vw;
}

.margen-right-vw-18 {
  margin-right: 18vw;
}

.margen-right-vw-19 {
  margin-right: 19vw;
}

.margen-right-vw-20 {
  margin-right: 20vw;
}

/* FIN MÁRGENES DINÁMICOS */
