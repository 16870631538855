/************************ Colores **********************/
:root {
  --color-azul-oscuro: #183b5a;
  --color-azul-oscuro-oscuro: #021424; /* VALOR HARDCODEADO, FALTA EL DE COMUNICACION */

  --color-celeste: #00adec;

  --color-celeste-claro-1: #adcbde;
  --color-celeste-claro-2: #02ebf6;
  --color-celeste-claro-3: #ddf1f9;
  --color-celeste-claro-4: #e8f5fa;
  --color-celeste-claro-5: #f5fbfd;

  --color-naranjita: rgb(234,128,36);
  /* *** *** Colores comunicación *** *** */
  --color-fondo-menu-lateral: #b8d5eb;
  --color-titulo-menu-lateral: #4f8baf;
  --color-hover-menu-lateral: #00ade5;
  --color-boton-seleccionado: #0a6d99;
  --color-azul-100: #00385c;

  /* Barra menu */
  --color-menu-celeste: #17a2b8;
  --color-menu-verde: #8df7ad;
  --color-contenedor-fotoPerfil: #c5e7ed;
  --color-contenedor-iconoPortal: #6dc4d2;

  --color-boton-estado-neutro: #4f8baf;
  --color-submenu-seleccionado: #105f6b;
  --color-fondo-botones-desplegado: #92bfe0;
  --color-opcion-tr-table: #b8d5eb;

  --color-background-cerrar-sesion: rgba(255, 115, 0, .6);
  --color-borde-cerrar-sesion: rgba(255, 115, 0, .2);

  /* Turnos */
  --color-box-turno: #deebf6;
  --color-head-tabla-fechas: #e5f3f9;
  --color-texto-especialidad: #0a6d99;
  --color-arrow-back: #2e6da5;

  /* home */
  --color-fondo-titulo-tarjeta: rgba(109, 196, 210, 0.4);
  --color-fondo-tarjeta: rgba(184, 213, 235, 0.3);
  --color-fondo-titulo-tarjeta-naranja: rgba(234, 128, 36, .8);
}
