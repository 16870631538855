@import "~material-design-icons/iconfont/material-icons.css";
@import url(//fonts.googleapis.com/css?family=Lato:400,700);

@import url(../src/app/_styles/colores.css);
@import url(../src/app/_styles/margenes.css);
@import url(../src/app/_styles/links.css);
@import url(../src/app/_styles/fondos.css);
@import url(../src/app/_styles/tablas.css);
@import url(../src/app/_styles/botones.css);


html,
body {
  height: 100%;
  margin: 0;
}

select {
  color: rgb(43, 100, 132);
}

select option {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(244, 244, 244) !important;
}

.mat-expansion-panel {
  background-color: var(--color-azul-oscuro) !important;
}

.mat-expansion-panel-header-title {
  color: white !important;
}
.mat-panel-title {
  background-color: var(--color-celeste) !important;
}

.mat-expansion-panel-spacing {
  margin: 0px 0;
}

.mat-drawer {
  background-color: white;
}

.mat-drawer-container {
  background-color: white !important;
  /*color: theming.get-color-from-palette($foreground, text);*/
}

#turnos-body {
  /* background-color: rgb(244, 244, 244) !important; */
  margin: 2%;
}

button:focus {
  outline: none;
}


.dialog-sin-overflow .mat-dialog-container{
  overflow: hidden !important;
}

.my-class .mat-dialog-container {
  height: calc(100vh - 30vh);
  width: calc(100vw - 40vw);
  overflow: hidden !important;
}

@media (max-width: 767px) {

  .my-class .mat-dialog-container {
    height: calc(100vh - 50vh);
    width: calc(100vw - 5vw);
  }
}

/************* LETRAS **************/
@font-face {
  font-family: "latosemibold";
  src: url("assets/fonts/lato-semibold-webfont.woff2") format("woff2"),
    url("assets/fonts/lato-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.latosemibold {
  font-family: "latosemibold", sans-serif;
}

@font-face {
  font-family: "latoregular";
  src: url("assets/fonts/Lato-Regular.woff2") format("woff2"),
    url("assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.latoregular {
  font-family: "latoregular", sans-serif;
}

@font-face {
  font-family: "D-DINCondensed";
  src: url("assets/fonts/D-DINCondensed.woff2") format("woff2"),
    url("assets/fonts/D-DINCondensed.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.D-DINCondensed {
  font-family: "D-DINCondensed", sans-serif;
}

@font-face {
  font-family: "D-DINCondensed-Bold";
  src: url("assets/fonts/D-DINCondensed-Bold.woff2") format("woff2"),
    url("assets/fonts/D-DINCondensed-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.D-DINCondensed-Bold {
  font-family: "D-DINCondensed-Bold", sans-serif;
}

.letra-azul-oscuro {
  color: var(--color-azul-oscuro);
}
.letra-blanca {
  color: white;
}



/*** mat icons ***/
mat-icon:hover {
  cursor: pointer;
}

/************************ Botón con Spinner **************************/
@keyframes spinnerKeyFrame {
  to {
    transform: rotate(360deg);
  }
}

.spinnerButton::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinnerKeyFrame 0.8s linear infinite;
}

.container-infinite-scroll {
  animation: animacionTabla 0.5s;
}

table {
  animation: animacionTabla 1.5s;
}

.volver {
  background-color: var(--color-titulo-menu-lateral) !important;
  color: white !important;
}
.volver:hover {
  background-color: var(--color-hover-menu-lateral) !important;
}
/* VERSION MOBILE */
@media (max-width: 1290px) {
  .titulo {
    font-family: "latoregular", sans-serif;
    font-weight: 500;
    color: var(--color-boton-estado-neutro);
    font-size: 39pt;
    padding-right: 5vw;
  }
  .subtitulo {
    font-family: "latoregular", Arial, Helvetica Neue, Helvetica, sans-serif !important;
    font-weight: 500;
    color: var(--color-azul-100);
    font-size: 29pt;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header p,
  .ui.button,
  button,
  input[type="submit"],
  a,
  th,
  td {
    /* 	font-family: droid-serif, serif; */
    font-family: "latoregular", Arial, Helvetica Neue, Helvetica, sans-serif !important;
  }

  table thead:not(.mat-calendar-table-header) {
    text-align: left;
    display: table;
    width: 64vw;
  }
  table tbody:not(.mat-calendar-body) {
    margin-top: 1vh;
    text-align: start;
    table-layout: fixed;
    background-color: transparent;
    border-collapse: separate;
    border-spacing: 0.1em 0.5em;
    height: auto;
    display: table;
    text-indent: 0;
  }
  .container {
    width: 85vw;
  }
  .table-container {
    margin-right: 5vw;
    overflow-x: scroll;
    overflow-y: auto;
    width: 85vw;
    max-height: 90vh;
  }
  td:not(.mat-calendar-body-cell-container) {
    min-width: 25vw;
    width: 25vw;
    word-break: break-word;
    overflow: hidden;
    -webkit-hyphens: auto;
    hyphens: auto;
    font-size: x-small;
    text-align: center;
  }
  td:not(.mat-calendar-body-cell-container) > button {
    width: 25vw;
  }
  thead:not(.mat-calendar-table-header) th {
    min-width: 25vw;
    width: 25vw;
    word-break: break-word;
    font-size: xx-small;
    text-align: center;
  }
  .boton {
    font-size: small;
  }

  td:not(.mat-calendar-body-cell-container):last-child > button {
    font-size: 7pt;
  }
}

/* VERSION DESKTOP */
@media (min-width: 1290px) {
  .titulo {
    font-family: "Lato", Arial, Helvetica Neue, Helvetica, sans-serif !important;
    font-weight: 500;
    color: var(--color-boton-estado-neutro);
    font-size: 39pt;
    padding-right: 5vw;
  }
  .subtitulo {
    font-family: "Lato", Arial, Helvetica Neue, Helvetica, sans-serif !important;
    font-weight: 500;
    color: var(--color-azul-100);
    font-size: 29pt;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header p,
  .ui.button,
  button,
  input[type="submit"],
  a,
  th,
  td {
    /* 	font-family: droid-serif, serif; */
    font-family: "Lato", Arial, Helvetica Neue, Helvetica, sans-serif !important;
  }

  table thead:not(.mat-calendar-table-header) {
    text-align: center;
    width: 72vw;
    display: table;
    position: absolute;
    padding-top: 5vh;
  }
  table tbody:not(.mat-calendar-body) {
    margin-top: 8vh;
    text-align: center;
    table-layout: fixed;
    border-collapse: separate;
    background-color: transparent;
    border-spacing: 0 0.5em;
    width: 72vw;
    height: auto;
    display: table;
    text-indent: initial;
  }
  .table-container {
    overflow-y: auto;
    overflow-x: hidden;
    width: 80vw;
    height: auto;
    margin-left: -2vw;
  }
  .botones {
    margin-left: -1vw;
    margin-top: 3vh;
  }
}

.mat-form-field-outline {
  background-color: white !important;
  /*border-radius: 5px; */
}

body{
  background-color: transparent;
}

@keyframes animacionTabla {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


/* HEADERS */
.barra-inicial{
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}  
.barra-inicial-celeste{
  background: linear-gradient(90deg, rgba(45,159,217,1) 0%, rgba(146,207,184,1) 100%);    
}
.barra-inicial-roja{
  background: linear-gradient(90deg, rgba(236,75,69,1) 0%, rgba(230,201,158,1) 100%); 
}
.barra-inicial-naranja{
  background: linear-gradient(90deg, rgba(242,113,72,1) 0%, rgba(226,153,93,1) 100%); 
}
/*  */

/* BARRAS_PROGRESO */
.barra-progreso-celeste .mat-progress-bar-fill::after {
  background: linear-gradient(90deg, rgba(45,159,217,1) 0%, rgba(146,207,184,1) 100%);    
}

/* CIRCULO_PROGRESO */
.mat-progress-spinner circle, .mat-spinner circle{
  stroke: rgba(146,207,184,1) !important;
}

/*  */
@media screen and (min-width:1290px){
  .barra-inicial{       
    height: 40px;
    top: -25px;
    left: -40px;
    width: 115%;          
  }  
  
  #cruz{
    height: 60px;
    position: relative;
    bottom: 10px;  
  }
  #cruz:hover{
      cursor: pointer;
  }

  .dialog{
    width: 500px;
  }
}

/* MOBILE */
@media screen and (max-width: 1290px){
  .dialog{
      width: 250px;
  }
  .barra-inicial{        
    height: 40px;
    top: -25px;
    left: -40px;
    width: 125%;      
  }   
  #cruz{
    height: 60px;
    position: relative;
    bottom: 10px;
  }
}

@media all and (max-width: 1290px) and (orientation: portrait) {
  /* styles for portrait orientation */
  
}

@media all and (max-width: 1290px) and (orientation: landscape) {
  /* styles for landscape orientation */
  #cruz{   
    right: 60px;
  }
}


