
th:first-child {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    padding-left: 2em;
  }
  th:last-child {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  td:not(.mat-calendar-body-cell-container):first-child {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    padding-left: 1em;
  }
  td:not(.mat-calendar-body-cell-container):not(
      .profesional-historial-compartido
    ):not(.nombre-estudio-historial):not(.td-foot):last-child {
    text-align: right;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  
  td:not(.mat-calendar-body-cell-container):last-child {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  td:not(.mat-calendar-body-cell-container):last-child > button {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    padding: 0.25em 1em 0.25em 1em;
    border: none;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
  }
  
  td:not(.mat-calendar-body-cell-container):last-child > img {
    padding: 0.25em 1em 0.25em 1em;
    border: none;
  }
  
  td:not(.mat-calendar-body-cell-container):last-child > mat-icon {
    padding: 0.25em 1.5em 0.25em 1em;
    border: none;
    color: var(--color-azul-100);
  }
  td:not(.mat-calendar-body-cell-container):last-child > mat-icon:hover {
    color: var(--color-hover-menu-lateral);
  }
  
  tfoot > td:not(.mat-calendar-body-cell-container):last-child {
    text-align: center;
  }
  
  thead:not(.mat-calendar-table-header) th {
    /* background: linear-gradient(to bottom, white, var(--color-fondo-botones-desplegado)); */
    /* background: linear-gradient(to top, white, var(--color-fondo-botones-desplegado)); */
    background-color: white;
    padding-bottom: 0.8em;
    width: 10vw;
    /* background: radial-gradient(ellipse at top, white, transparent),
              radial-gradient(ellipse at bottom, var(--color-fondo-botones-desplegado), transparent); */
  
    /* background: radial-gradient(white,var(--color-fondo-menu-lateral)); */
  }
  
  tfoot td {
    background: radial-gradient(white, var(--color-fondo-menu-lateral));
    text-align: center;
  }
  
  tfoot {
    color: var(--color-azul-oscuro);
    font-weight: 600;
    letter-spacing: 0.15em;
    text-align: center;
  }
  
  table thead:not(.mat-calendar-table-header) {
    text-transform: uppercase;
    color: var(--color-azul-oscuro);
    letter-spacing: 0.15em;
  }
  
  tr {
    color: var(--color-azul-oscuro);
  }
  
  tr:nth-child(3n-2)
    > td:not(.mat-calendar-body-label, .mat-calendar-body-cell-container) {
    background: rgb(184, 213, 235);
    background: linear-gradient(
      27deg,
      rgba(184, 213, 235, 0.7) 100%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  tr:nth-child(3n-2)
    > td:last-child:not(
      .mat-calendar-body-label,
      .mat-calendar-body-cell-container
    )
    > button {
    background-color: var(--color-azul-100);
  }
  tr:nth-child(3n-2)
    > td:last-child:not(
      .mat-calendar-body-label,
      .mat-calendar-body-cell-container
    )
    > button:hover {
    background-color: var(--color-hover-menu-lateral);
  }
  
  tr:nth-child(3n-1)
    > td:not(.mat-calendar-body-label, .mat-calendar-body-cell-container) {
    background: rgb(146, 191, 224);
    background: linear-gradient(
      27deg,
      rgba(146, 191, 224, 0.700717787114846) 100%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  tr:nth-child(3n-1)
    > td:last-child:not(
      .mat-calendar-body-label,
      .mat-calendar-body-cell-container
    )
    > button {
    background-color: var(--color-fondo-botones-desplegado);
  }
  tr:nth-child(3n-1)
    > td:last-child:not(
      .mat-calendar-body-label,
      .mat-calendar-body-cell-container
    )
    > button:hover {
    background-color: var(--color-hover-menu-lateral);
  }
  
  tr:nth-child(3n)
    > td:not(.mat-calendar-body-label, .mat-calendar-body-cell-container) {
    background: rgb(79, 139, 175);
    background: linear-gradient(
      27deg,
      rgba(79, 139, 175, 0.55) 100%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  tr:nth-child(3n)
    > td:last-child:not(
      .mat-calendar-body-label,
      .mat-calendar-body-cell-container
    )
    > button {
    background-color: var(--color-boton-estado-neutro);
  }
  tr:nth-child(3n)
    > td:last-child:not(
      .mat-calendar-body-label,
      .mat-calendar-body-cell-container
    )
    > button:hover {
    background-color: var(--color-hover-menu-lateral);
  }