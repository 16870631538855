.boton {
  height: 35px;
  font-size: 16px;
  /* background-color: var(--color-azul-100); */
  background-color: var(--color-titulo-menu-lateral);
  border: 5px solid;
  /* border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em; */
  border-radius: 10px;
  padding: 0.25em 1em 0.25em 1em;
  border: none;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: white; 
}

.boton:hover {
  background-color: var(--color-hover-menu-lateral);
  animation: fadeInHover .5s;
}

.borrar_usuario{
  color: rgb(234, 128, 36);    
  background-color: white;    
  border: 0.3vh solid rgb(234, 128, 36);
  border-radius: 10px;
  font-weight: 600;
}
.borrar_usuario:hover {
  background-color: rgb(234, 128, 36);
  color: white;
  animation: fadeInHover .5s;
}



.boton-cancelar {
  background-color: rgb(138, 16, 2) !important;
  border-radius: 0rem;
  border: 0px solid transparent;
  color: white;
  text-decoration: none;
}

.boton-dialog {
  margin: 1vw;   
  height: 3em;
  border: solid;
  border-width: 5px;
  border-color: white;
  /* border-top-left-radius: 35%;
  border-bottom-right-radius: 35%; */
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .5);
  /* text-transform: uppercase; */
  color: white;
}
.boton-dialog:hover{
  font-weight: 600;
  cursor: pointer;
}

/* MAT ICONS */
.boton-mat-icon{
  background-color: var(--color-titulo-menu-lateral) !important;
}
.boton-mat-icon:hover{
  background-color: var(--color-celeste) !important;
}

@media screen and (min-width: 1290px) {

  .boton-cerrar, .boton-confirmar, .recuperar-usuario{
    background: linear-gradient(90deg, rgba(0,0,0,.22) 0%, rgba(0,0,0,.65) 100%);
  }

  .boton-cerrar:hover{
    background: rgb(245,163,170);
    background: linear-gradient(90deg, rgba(245,163,170,1) 0%, rgba(238,83,74,1) 100%);
  }

  .boton-confirmar:hover{
    background: rgb(45,159,217);
    background: linear-gradient(90deg, rgba(45,159,217,1) 0%, rgba(146,207,184,1) 100%);  
  }

  .recuperar-usuario:hover{
    background: rgb(234,128,36);
    background: linear-gradient(90deg, rgba(234,128,36,1) 0%, rgba(234,212,36,1) 100%);
    color: white;
  }
}


/* MOBILE */
@media screen and (max-width: 1290px){
  .boton{
    height: auto;
  }
  .boton-cerrar{
    background: rgb(245,163,170);
    background: linear-gradient(90deg, rgba(245,163,170,1) 0%, rgba(238,83,74,1) 100%);
  }

  .boton-confirmar{
    background: rgb(45,159,217);
    background: linear-gradient(90deg, rgba(45,159,217,1) 0%, rgba(146,207,184,1) 100%); 
  }

  .recuperar-usuario{
    background-color: rgb(234, 128, 36);
    color: white;
  }
  
}